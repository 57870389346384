<template>
  <AppMainContainer
    center-class="offer"
    :show-modal="!!currentModal || showBuyModal"
    @isScroll="(val) => {isScroll = val}"
  >
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot" />
    </template>

    <template #center>
      <div
        v-if="vacancieCardData && vacancieCardData.id"
        class="vacancies-item"
      >
        <div
          v-if="root"
          class="vacancies-item__controls"
        >
          <div
            v-if="vacancieCardData.isComplete && vacancieCardData.isActive"
            class="vacancies-item__control-deactivate"
            @click="deactivate"
          >
            {{ $t('vacancies.controls.deactivate') }}
          </div>
          <div
            v-if="vacancieCardData.isComplete && !vacancieCardData.isActive"
            class="vacancies-item__control-deactivate"
            @click="active"
          >
            {{ $t('vacancies.controls.activate') }}
          </div>
          <!--          <div-->
          <!--            class="vacancies-item__control-delete"-->
          <!--            @click="remove"-->
          <!--          >-->
          <!--            {{ $t('vacancies.controls.delete') }}-->
          <!--          </div>-->
        </div>

        <div class="vacancies-item__block">
          <div class="vacancies-item__title">
            {{ $t('vacancies.card.main_info') }}
          </div>
          <div
            v-if="root"
            class="vacancies-item__editing"
            @click="currentModal = modalName.createVacancie"
          >
            <IconPanEdit />
            <div class="vacancies-item__control-title">
              {{ $t('common.edit') }}
            </div>
          </div>
        </div>
        <div class="vacancies-item__sub-block">
          <div class="vacancies-item__sub-block__title">
            {{ `Для своих` }}
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ vacancieCardData.isPrivate ? 'ДА' : 'Нет' }}
          </div>
        </div>
        <div class="vacancies-item__sub-block">
          <div class="vacancies-item__sub-block__title">
            {{ `Внутреннее название` }}
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ vacancieCardData.title }}
          </div>
        </div>
        <div class="vacancies-item__sub-block">
          <div class="vacancies-item__sub-block__title">
            {{ `Внешнее название` }}
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ vacancieCardData.externalTitle }}
          </div>
        </div>
        <div class="vacancies-item__sub-block">
          <div class="vacancies-item__sub-block__title">
            {{ $t('vacancies.card.main_competence') }}
          </div>
          <div
            v-if="vacancieCardData.profession && vacancieCardData.profession.mainCompetence"
            class="vacancies-item__ sub-block__value"
          >
            {{ vacancieCardData.profession.mainCompetence.title }}
          </div>
        </div>
        <div class="vacancies-item__sub-block">
          <div class="vacancies-item__sub-block__title">
            {{ $t('vacancies.card.profession') }}
          </div>
          <div
            v-if="vacancieCardData.profession"
            class="vacancies-item__sub-block__value"
          >
            {{ vacancieCardData.profession.title }}
          </div>
        </div>
        <div class="vacancies-item__separator" />
        <div class="vacancies-item__sub-block vacancies-item__sub-block_margin_small">
          <div
            class="vacancies-item__sub-block__title"
          >
            <img
              v-if="vacancieCardData.requiredMedicalBook"
              src="@/assets/icon-check.svg"
            >
            <img
              v-else
              src="@/assets/dialog-close.svg"
            >
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ $t('vacancies.docs.requiredMedicalBook') }}
          </div>
        </div>
        <div class="vacancies-item__sub-block vacancies-item__sub-block_margin_small">
          <div
            class="vacancies-item__sub-block__title"
          >
            <img
              v-if="vacancieCardData.selfEmployeeOnly"
              src="@/assets/icon-check.svg"
            >
            <img
              v-else
              src="@/assets/dialog-close.svg"
            >
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ $t('vacancies.docs.selfEmployeeOnly') }}
          </div>
        </div>
        <div class="vacancies-item__sub-block vacancies-item__sub-block_margin_small">
          <div class="vacancies-item__sub-block__title">
            <img
              v-if="vacancieCardData.laborerHasMaterialResponsibility"
              src="@/assets/icon-check.svg"
            >
            <img
              v-else
              src="@/assets/dialog-close.svg"
            >
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ $t('vacancies.docs.laborerHasMaterialResponsibility') }}
          </div>
        </div>
        <div class="vacancies-item__sub-block vacancies-item__sub-block_margin_small">
          <div class="vacancies-item__sub-block__title">
            <img
              v-if="vacancieCardData.questionList && vacancieCardData.questionList.length"
              src="@/assets/icon-check.svg"
            >
            <img
              v-else
              src="@/assets/dialog-close.svg"
            >
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ $t('vacancies.docs.hasQuestionList') }}
          </div>
        </div>
        <div class="vacancies-item__block vacancies-item__block_margin_large">
          <div class="vacancies-item__title">
            {{ $t('vacancies.card.competence') }}
          </div>
        </div>
        <div v-if="competenceToShow && competenceToShow.length">
          <div
            v-for="competence in competenceToShow"
            :key="competence.id"
            class="vacancies-item__sub-block vacancies-item__sub-block_margin_small"
          >
            <div
              class="vacancies-item__sub-block__title"
            >
              <img src="@/assets/icon-check.svg">
            </div>
            <div class="vacancies-item__sub-block__value">
              {{ competence.title }}
            </div>
          </div>
        </div>
        <div
          v-if="vacancieCardData.competenceList && vacancieCardData.competenceList.length"
          class="vacancies-item__sub-block vacancies-item__sub-block_margin_medium"
        >
          <div class="vacancies-item__sub-block__title">
            <img
              v-if="hasFixedPay"
              src="@/assets/icon-check.svg"
            >
          </div>
          <div
            v-if="startCompetenceCount === 3"
            class="vacancies-item__control-show-all"
            @click="showAllCompetences"
          >
            {{ $t('common.show_all') }}
          </div>
        </div>
        <div class="vacancies-item__block vacancies-item__block_margin_large">
          <div class="vacancies-item__title">
            {{ $t('vacancies.card.pay_and_region') }}
          </div>
        </div>
        <div
          v-for="regionSettingItem in vacancieCardData.regionSetting"
          :key="regionSettingItem.id"
        >
          <div class="vacancies-item__sub-block vacancies-item__sub-block_margin_medium">
            <div class="vacancies-item__sub-block__title">
              <img
                v-if="regionSettingItem.hasFixedPay"
                src="@/assets/icon-check.svg"
              >
              <img
                v-else
                src="@/assets/dialog-close.svg"
              >
            </div>
            <div class="vacancies-item__sub-block__value">
              {{ $t('vacancies.card.fix_pay_region') }}
            </div>
          </div>
          <div class="vacancies-item__sub-block">
            <div class="vacancies-item__sub-block__title">
              {{ $t('vacancies.grid_card.region') }}
            </div>
            <div class="vacancies-item__sub-block__value">
              {{ regionSettingItem.region.title }}
            </div>
          </div>
          <div class="vacancies-item__sub-block">
            <div class="vacancies-item__sub-block__title">
              {{ $t('vacancies.grid_card.sum_hour') }}
            </div>
            <div class="vacancies-item__sub-block__value">
              {{ regionSettingItem.payPerHour }} ₽
            </div>
          </div>
          <div
            v-if="!hasFixedPay"
            class="vacancies-item__sub-block"
          >
            <div class="vacancies-item__sub-block__title">
              {{ $t('vacancies.grid_card.sum_hour_max') }}
            </div>
            <div class="vacancies-item__sub-block__value">
              {{ regionSettingItem.maxPay }} ₽
            </div>
          </div>
          <div class="vacancies-item__sub-block">
            <div class="vacancies-item__sub-block__title">
              часовую оплату самозянятому на руки
            </div>
            <div class="vacancies-item__sub-block__value">
              {{ regionSettingItem.selfEmployerPay }} ₽
            </div>
          </div>
          <div
            v-if="!hasFixedPay"
            class="vacancies-item__sub-block"
          >
            <div class="vacancies-item__sub-block__title">
              Максимальная ставка за час самозянятому
            </div>
            <div class="vacancies-item__sub-block__value">
              {{ regionSettingItem.maxSelfEmployerPay }} ₽
            </div>
          </div>
        </div>
        <div class="vacancies-item__sub-block">
          <div class="vacancies-item__sub-block__title">
            {{ `Важная информация` }}
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ vacancieCardData.message }}
          </div>
        </div>
        <div class="vacancies-item__block vacancies-item__block_margin_large">
          <div class="vacancies-item__title">
            {{ $t('vacancies.card.questions') }}
          </div>
        </div>
        <div
          v-for="(question, ndx) in vacancieCardData.questionList"
          :key="question.id"
          class="vacancies-item__sub-blocfk"
        >
          <div class="vacancies-item__sub-block__title">
            {{ ndx + 1 }}.
          </div>
          <div class="vacancies-item__sub-block__value">
            {{ question.description }}
          </div>
        </div>
      </div>
      <AppLoader v-else />
    </template>

    <template #modal>
      <VacanciesModalForm
        v-if="currentModal === modalName.createVacancie"
        :vacancie-card-data="vacancieCardData"
        style="padding: 30px"
        @close="currentModal = ''"
      />
      <VacanciesModalNewCompetence
        v-if="currentModal === modalName.newCompetence"
        @close="currentModal = ''; setUpdateCompetenceFlag(false)"
      />

      <AppModalSmall
        v-if="currentModal === modalName.deactivationModal"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          Вакансия деактивирована
        </h1>
        <p class="modal__description">
          Вакансия успешно деактивирована
        </p>
        <div class="modal__button">
          <AppButton
            @click.native="forceReload"
          >
            Ок
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalName.activationModal"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          Вакансия активирована
        </h1>
        <p class="modal__description">
          Вакансия успешно активирована
        </p>
        <div class="modal__button">
          <AppButton
            @click.native="forceReload"
          >
            Ок
          </AppButton>
        </div>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import IconPanEdit from '@/components/common/icons/IconPanEdit'
import VacanciesModalForm from '@/components/vacancies/VacanciesModalForm'
import VacanciesModalNewCompetence from '@/components/vacancies/VacanciesModalNewCompetence'
import AppButton from '@/components/common/simple/AppButton'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import userUtil from '@/utils/user-util'
import AppLoader from '@/components/AppLoader'
import PageHeader from '@/components/subheaders/PageHeader.vue'

const modalName = Object.freeze({
  removeOffer: 'removeOffer',
  createVacancie: 'createVacancie',
  newCompetence: 'newCompetence',
  deactivationModal: 'deactivationModal',
  activationModal: 'activationModal'
})

export { modalName }

export default {
  components: {
    PageHeader,
    AppLoader,
    AppModalSmall,
    AppButton,
    AppMainContainer,
    IconPanEdit,
    VacanciesModalForm,
    VacanciesModalNewCompetence
  },
  data () {
    return {
      isScroll: false,
      currentModal: '',
      showBuyModal: false,
      startCompetenceCount: 3,
      modalName
    }
  },
  computed: {
    ...mapGetters({
      vacancieCardData: 'vacancy/getVacancieCardData',
      competenceList: 'vacancy/getCompetenceList',
      updateCompetenceFlag: 'vacancy/getUpdateCompetenceFlag'
    }),
    root () {
      const user = this.$store.state.users.info
      return user && user.id && userUtil.isRoot(user)
    },
    competenceToShow () {
      if (!this.vacancieCardData && !this.vacancieCardData.competenceList &&
        !this.vacancieCardData.competenceList.length) {
        return []
      }
      if (!this.vacancieCardData.competenceList) return []

      return this.vacancieCardData && this.vacancieCardData.competenceList &&
      this.vacancieCardData.competenceList.slice(0, this.startCompetenceCount)
    },
    hasFixedPay () {
      return this.vacancieCardData.regionSetting && this.vacancieCardData.regionSetting[0] &&
        this.vacancieCardData.regionSetting[0].hasFixedPay
    },
    regionTitle () {
      return this.vacancieCardData.regionSetting && this.vacancieCardData.regionSetting[0] &&
        this.vacancieCardData.regionSetting[0].region && this.vacancieCardData.regionSetting[0].region.title
    },

    regionSettingExists () {
      return this.vacancieCardData && this.vacancieCardData.regionSetting && this.vacancieCardData.regionSetting.length
    },
    getCurrentRegion () {
      if (!this.regionSettingExists) {
        return null
      }
      return this.vacancieCardData.regionSetting[0]
    },
    selfEmploymentMaxPay () {
      if (!this.getCurrentRegion) {
        return ''
      }
      return this.getCurrentRegion.maxSelfEmployerPay
    },
    selfEmployerPayPerHour () {
      if (!this.vacancieCardData) {
        return ''
      }
      return this.vacancieCardData.selfEmployerHourlyPay
    },
    maxPay () {
      return this.vacancieCardData.regionSetting && this.vacancieCardData.regionSetting[0] &&
        this.vacancieCardData.regionSetting[0] && this.vacancieCardData.regionSetting[0].maxPay
    },
    payPerHour () {
      if (!this.vacancieCardData) {
        return ''
      }
      return this.vacancieCardData.hourlyPay
    },
    officeUser () {
      return this.$store.state.office.userOffice
    },
    vacanciesList () {
      return this.$store.state.vacancy.items
    },
    vacancyId () {
      return this.$route.params.vacancyId
    },
    currentUser () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.currentUser) || userUtil.isAdmin(this.currentUser)
    },
  },
  watch: {
    updateCompetenceFlag (flag) {
      if (flag) {
        this.currentModal = ''
        this.currentModal = modalName.newCompetence
      }
    }
  },
  created () {
    this.fetchVacancieById({ id: this.vacancyId })
  },
  methods: {
    ...mapActions({
      fetchVacancieById: 'vacancy/fetchVacancieById',

      deactivateVacancie: 'vacancy/deactivateVacancie',
      activateVacancie: 'vacancy/activateVacancie',
      removeVacancieById: 'vacancy/removeVacancieById',
      setUpdateCompetenceFlag: 'vacancy/setUpdateCompetenceFlag',
      fetchVacancyList: 'vacancy/fetchVacancyList'
    }),
    remove () {
      this.removeVacancieById({ id: this.vacancyId }).then(() => {
        this.fetchVacancyList()
        this.$router.go(-1)
      })
    },
    deactivate () {
      this.deactivateVacancie({ id: this.vacancyId })
        .then(() => {
          this.currentModal = this.modalName.deactivationModal
          // this.fetchVacancieById({ id: this.vacancyId })
          // this.fetchVacancyList()
          // this.$router.go(-1)
          // window.location.reload()
        })
    },
    active () {
      this.activateVacancie({ id: this.vacancyId })
        .then(() => {
          this.currentModal = this.modalName.activationModal
          // this.fetchVacancieById({ id: this.vacancyId })
          // this.$router.go(-1)
        })
    },
    forceReload () {
      this.currentModal = ''
      this.fetchVacancieById({ id: this.vacancyId })
    },
    showAllCompetences () {
      this.startCompetenceCount = this.vacancieCardData && this.vacancieCardData.competenceList &&
        this.vacancieCardData.competenceList.length + 1
    }
  }
}
</script>
<style lang="sass" scoped>
  .vacancies-item
    position: relative

    &__controls
      position: absolute
      right: 0
      top: 30px
      display: flex
      flex-direction: column
      margin-top: 30px

    &__separator
      width: 80%
      opacity: .2
      border-bottom: 1px solid #757575
      margin-bottom: 20px

    &__block
      display: flex
      justify-content: space-between
      margin-bottom: 20px

      &_margin_large
        margin: 40px 0

    &__title
      @include fontStyle(500, 14px)
      width: 200px
      text-align: right

    &__editing
      display: flex
      justify-content: space-between
      cursor: pointer

    &__control-show-all
      margin-top: 5px
      color: rgba(0, 0, 0, 0.61)
      @include fontStyle(800, 14px)
      cursor: pointer

    &__control-deactivate,
    &__control-delete,
    &__control-title
      margin-left: 8px
      color: rgba(0, 0, 0, 0.61)
      @include fontStyle(800, 14px)
      cursor: pointer

    &__control-deactivate
      text-align: right
      margin-bottom: 20px

    &__control-delete
      text-align: right
      color: #ED462F

    &__sub-block
      display: flex
      align-items: center
      font-family: Montserrat, sans-serif
      margin-bottom: 30px

      &_margin_small
        margin-bottom: 8px
        min-height: 28px

      &_margin_medium
        margin-bottom: 25px

      &__title
        text-align: right
        width: 200px
        @include fontStyle(500, 13px)
        margin-right: 35px
        color: #000
        opacity: .6

      &__icon
        width: 25px
        height: 25px

      &__value
        font-size: 13px
        margin-right: 40px
        color: #000

</style>
