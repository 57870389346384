<template>
  <AppModalMiddle>
    <div class="vacancies-modal-competence">
      <div class="vacancies-modal-competence__title">
        {{ $t('vacancies.new_competence') }}
      </div>
      <AppTextArea
        v-model="competenceText"
        :placeholder="competencePlaceholder"
        :hide-close="true"
        size="medium"
      />
      <div class="vacancies-modal-competence__controls">
        <AppButton
          :disabled="!competenceText.length"
          @click.native="createRequest"
        >
          {{ submitTitle }}
        </AppButton>
        <div
          class="vacancies-modal-competence__controls__cancel"
          @click="$emit('close')"
        >
          {{ $t('common.cancel') }}
        </div>
      </div>
    </div>
  </AppModalMiddle>
</template>
<script>
import { mapActions } from 'vuex'
import AppModalMiddle from '@/components/common/modal/AppModalMiddle'
import AppTextArea from '@/components/common/simple/AppTextArea'
import AppButton from '@/components/common/simple/AppButton'

export default {
  name: 'VacanciesModalNewCompetence',
  components: {
    AppModalMiddle,
    AppTextArea,
    AppButton
  },
  data () {
    return {
      competenceText: ''
    }
  },
  computed: {
    competencePlaceholder () {
      return this.$t('vacancies.new_competence_description')
    },
    submitTitle () {
      return this.$t('vacancies.new_competence_send_request')
    }
  },
  methods: {
    ...mapActions({
      competenceCreateRequest: 'vacancy/competenceCreateRequest'
    }),
    createRequest () {
      this.competenceCreateRequest({
        competence: this.competenceText,
        title: this.competenceText
      }).then(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
<style lang="sass">
  .vacancies-modal-competence
    padding: 55px 50px

    &__controls
      margin-top: 30px
      display: flex
      flex-direction: column
      align-items: center

      &__cancel
        margin-top: 20px
        @include fontStyle(800, 18px)
        cursor: pointer
        opacity: .7

    &__title
      text-align: center
      margin-bottom: 40px
      @include fontStyle(800, 24px)
</style>
